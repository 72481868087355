import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function DashboardNataru(props: any) {
  // const [token, setToken] = useState('');
  const Nataru2023 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="538"
          src="https://lookerstudio.google.com/embed/reporting/9437e2a9-71b4-4557-933a-80369f13573c/page/p_1rei7xlkcd"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };
  

  return (
    <div>
      <ContentHeader title="Dashboard Nataru" />
      <div className="card card-outline card-primary" style={{ width:'100%',height:'100vh'}}>
      <div className="card-header border-0 ui-sortable-handle"  style={{cursor: 'move'}}>
        <h3 className="card-title">Angkutan Natal dan Tahun Baru</h3>
        <div className="card-tools">
        <button type="button" className="btn btn-primary btn-sm" data-card-widget="collapse" title="Collapse">
        <i className="fas fa-minus"></i>
        </button>
        </div>
        </div>
        <div className="card-header">
          <h3 className="card-title"> </h3>
        </div>
        <div className="card-body">
        <Nataru2023 />
        </div>
      </div>
    </div>
  );
}

export default DashboardNataru;
